<template>
  <div class="wrongBooks">
    <div class="startClass">
      <div class="startClassTitle">错题统计</div>
      <div class="startClassContent">
        <div
          v-if="!loading && bookList.length < 1"
          style="
            margin: auto;
            color: #c5c5c5;
            font-size: 13px;
            text-align: center;
          "
        >
          暂无数据
        </div>
        <div v-else>
          <div
            class="bookItem flex-row"
            v-for="item in bookList"
            :key="item.bookId"
            @click="toWrongBookClasses(item)"
          >
            <div class="bookCover">
              <img :src="item.cover" />
            </div>
            <div class="bookInfo flex-col flex-jc-bt">
              <div class="bookName">{{ item.bookName }}</div>
              <div class="checkTime flex-row flex-al-center">
                <span>{{ item.showTime }}</span>
                <img
                  src="https://img01.yzcdn.cn/upload_files/2022/03/23/FvB4cKTCO94NyKUqvVwCRaM9ekii.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listWrongData4Book } from "../../API/statistical";
import { formatDate } from "@/static/js/zjs";
import {
  initZidian,
  getJoinName,
} from "@/project/smartHomeworkUserClient/common/bookName";
import { ossUrl } from "@/static/js/oss";
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {
      loading: true,
      bookList: [],
    };
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch(
      "smartHomeworkUserClient/user/getStudentUserInfo"
    );
    await initZidian();
    const studentUserInfo =
      this.$store.state.smartHomeworkUserClient.user.studentUserInfo;
    listWrongData4Book({
      ...studentUserInfo,
    }).then((res) => {
      if (res.status === 200) {
        this.bookList = res.data.map((i) => {
          const { joinName } = getJoinName(i.book);
          i.bookName = joinName;
          i.cover = ossUrl(i.book.thumbCoverPath);
          i.showTime = formatDate(
            "yyyy年MM月dd日 HH:mm:ss",
            i.latestUpdateTime
          );
          return i;
        });
      }
      this.loading = false;
    });
  },
  methods: {
    toWrongBookClasses(item) {
      console.log(item);
      // const query = urlSearch(item);
      // wx.navigateTo({
      //   url: "/pages/studentClient/statisticalData/wrongBookClasses" + query,
      // });
      this.$router.push({
        path: "/smartHomeworkUserClient/statisticalData/wrongBookClasses",
        query: {
          ...item,
        },
      });
    },
  },
})
export default class wrongBooks extends Vue {}
</script>

<style lang="scss">
.wrongBooks {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: auto;
  background: linear-gradient(180deg, #edf7ff 0%, rgba(245, 249, 252, 0) 100%);

  .startClass {
    width: 347px;
    margin-top: 14px;

    .startClassTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 88px;
      height: 39px;
      background: #ffffff;
      border-radius: 10px 10px 0 0;
      font-size: 14px;
      color: #000000;
    }

    .startClassContent {
      width: 100%;
      /*height: 414px;*/
      background: #ffffff;
      border-radius: 0 12px 12px 12px;
      padding: 20px 15px 20px 16px;

      .bookItem {
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }

        .bookCover {
          flex-shrink: 0;
          width: 65px;
          height: 90px;
          border: 1px solid #e4e4e4;
          border-radius: 5px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .bookInfo {
          margin-left: 11px;

          .bookName {
            margin-top: 2px;
            font-size: 14px;
            line-height: 20px;
            color: #202020;
          }

          .checkTime {
            margin-bottom: 11px;
            font-size: 12px;
            color: #999999;

            img {
              margin-left: 4px;
              width: 52px;
            }
          }
        }
      }
    }
  }
}
</style>
